export default function googleMapsPublic(originLatLng, mapParams) {
  const topics = mapParams.topics;
  const mapSettings = mapParams.mapSettings;
  const mapOverlay = mapParams.mapOverlay;
  const geoJson = mapParams.geoJson;
  const latInput = topics
    ? null
    : this.$refs.map.closest('[data-selector="form-field__map"').querySelector('[data-map-input="lat"]');
  const lngInput = topics
    ? null
    : this.$refs.map.closest('[data-selector="form-field__map"').querySelector('[data-map-input="lng"]');

  return {
    showOverlay: mapOverlay.mapOverlayVisible,
    showError: false,
    overlayURL: mapOverlay.mapOverlayImageUrl,
    overlay: {
      north: mapOverlay.overlayBoundNorth,
      south: mapOverlay.overlayBoundSouth,
      east: mapOverlay.overlayBoundEast,
      west: mapOverlay.overlayBoundWest,
    },
    groundOverlay: null,
    map: null,
    markerURL:
      mapParams.markerUrl || 'https://bpart-default-assets.s3.eu-central-1.amazonaws.com/img/map-default-marker.png',
    intersectionObserver: null,

    init() {
      // Create an IntersectionObserver to animate the markers
      this.intersectionObserver = new IntersectionObserver((entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            entry.target.classList.add('drop');
            this.intersectionObserver.unobserve(entry.target);
          }
        }
      });

      // Initialize the map (asynchronously)
      this.initMap();
    },

    async initMap() {
      // Load the Google Maps API libraries
      const { Map } = await google.maps.importLibrary('maps');
      const { AdvancedMarkerElement } = await google.maps.importLibrary('marker');
      const { Autocomplete } = await google.maps.importLibrary('places');

      // Create the map and set its options
      const mapOptions = {
        zoom: mapSettings.zoomLevel || 14,
        center: this.centerLatLng(),
        mapTypeId: mapSettings.mapType || 'roadmap',
        mapTypeControl: true,
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
        },
        gestureHandling: 'cooperative',
        mapId: 'MAP_ID',
      };
      let map = new Map(this.$refs.map, mapOptions);

      // Add a search box
      if (this.$refs.searchBox) {
        const placeAutocomplete = new Autocomplete(this.$refs.searchBox);

        placeAutocomplete.addListener('place_changed', async (ev) => {
          let value = this.$refs.searchBox.value;
          await this.findPlaces(value, map);
        });
      }

      // Add all entry markers (on homepage)
      if (google && map && topics) {
        topics.collection.forEach((topic) => {
          topic.entries.forEach((entry) => {
            const icon = document.createElement('img');
            icon.src = topic.iconUrl;

            const markerLatLng = new google.maps.LatLng(entry.position.lat, entry.position.lng);
            const title = entry.title.replace(/<[^>]*>?/gm, '');

            const marker = new AdvancedMarkerElement({
              map: map,
              position: markerLatLng,
              content: icon,
              title: title,
            });

            if (topics.type === 'topics') {
              const contentString =
                '<div>' +
                `<h3>${title}</h3>` +
                '<span class="link">' +
                `<a href="topics/${topic.id}/entries/${entry.id}">${mapSettings.detailsLink}</a>` +
                '</span>' +
                '</div>';

              const infoWindow = new google.maps.InfoWindow({
                content: contentString,
              });

              marker.addListener('gmp-click', () => {
                infoWindow.open({
                  anchor: marker,
                  map: map,
                  shouldFocus: false,
                });
              });
            }

            // Add a drop animation to the marker
            const content = marker.content;
            content.style.opacity = '0';
            content.addEventListener('animationend', (event) => {
              content.classList.remove('drop');
              content.style.opacity = '1';
            });
            this.intersectionObserver.observe(content);
          });
        });
      }

      // Add a clickable or draggable marker to input a location (for entry or profile)
      // Draggable markers can be moved by using the keyboard. To activate dragging,
      // press Option + Space or Option + Enter (Mac), ALT + Space or Alt + Enter (Windows).
      if (latInput && lngInput) {
        const lat = latInput.value || originLatLng.originLat;
        const lng = lngInput.value || originLatLng.originLng;
        const markerLatLng = new google.maps.LatLng(lat, lng);
        const icon = document.createElement('img');
        icon.src = this.markerURL;

        const marker = new AdvancedMarkerElement({
          map: map,
          position: markerLatLng,
          gmpDraggable: true,
          content: icon,
        });

        map.setCenter(markerLatLng);

        map.addListener('click', (mapsMouseEvent) => {
          const position = mapsMouseEvent.latLng;
          latInput.setAttribute('value', position.lat());
          lngInput.setAttribute('value', position.lng());
          marker.position = position;
        });

        marker.addListener('dragend', (evt) => {
          const position = marker.position;
          latInput.setAttribute('value', position.lat);
          lngInput.setAttribute('value', position.lng);
        });
      }

      // a method to get the bounds of the overlay
      const overlayBounds = () => {
        try {
          const overlayNorth = parseFloat(this.overlay.north);
          const overlaySouth = parseFloat(this.overlay.south);
          const overlayEast = parseFloat(this.overlay.east);
          const overlayWest = parseFloat(this.overlay.west);
          const bounds = new google.maps.LatLngBounds(
            new google.maps.LatLng(overlaySouth, overlayWest),
            new google.maps.LatLng(overlayNorth, overlayEast)
          );
          return bounds;
        } catch (error) {
          console.error('Could not build overlayBounds', error);
          return false;
        }
      };

      //Extend the Overlay class so we can add a ground overlay to the map
      class MapOverlay extends google.maps.OverlayView {
        constructor(bounds, image) {
          super();
          this.bounds = bounds;
          this.image = image;
        }
        //  onAdd is called when the map's panes are ready and the overlay has been added to the map.
        onAdd() {
          this.div = document.createElement('div');
          this.div.style.borderStyle = 'none';
          this.div.style.borderWidth = '0px';
          this.div.style.position = 'absolute';
          // Create the img element and attach it to the div.
          const img = document.createElement('img');
          img.src = this.image;
          img.style.width = '100%';
          img.style.height = '100%';
          img.style.position = 'absolute';
          this.div.appendChild(img);
          // Add the element to the "overlayLayer" pane.
          const panes = this.getPanes();
          panes.overlayLayer.appendChild(this.div);
        }
        draw() {
          // We use the south-west and north-east coordinates of the overlay to peg it to the correct position and size.
          // To do this, we need to retrieve the projection from the overlay.
          const overlayProjection = this.getProjection();
          // Retrieve the south-west and north-east coordinates of this overlay in LatLngs and convert them to pixel coordinates.
          // We'll use these coordinates to resize the div.
          const sw = overlayProjection.fromLatLngToDivPixel(this.bounds.getSouthWest());
          const ne = overlayProjection.fromLatLngToDivPixel(this.bounds.getNorthEast());

          // Resize the image's div to fit the indicated dimensions.
          if (this.div) {
            this.div.style.left = sw.x + 'px';
            this.div.style.top = ne.y + 'px';
            this.div.style.width = ne.x - sw.x + 'px';
            this.div.style.height = sw.y - ne.y + 'px';
          }
        }
        //  The onRemove() method will be called automatically from the API if we ever set the overlay's map property to 'null'.
        onRemove() {
          if (this.div) {
            this.div.parentNode.removeChild(this.div);
            delete this.div;
          }
        }
      }

      // Add the overlay and geoJson to the map
      if (google && this.showOverlay) {
        if (overlayBounds() && this.overlayURL) {
          this.groundOverlay = new MapOverlay(overlayBounds(), this.overlayURL);
          this.groundOverlay.setMap(map);
        }

        if (geoJson) {
          map.data.addGeoJson(JSON.parse(geoJson));
          map.data.setStyle(function (feature) {
            return {
              fillColor: feature.getProperty('fill'),
              fillOpacity: feature.getProperty('fill-opacity'),
              strokeColor: feature.getProperty('stroke'),
              strokeOpacity: feature.getProperty('stroke-opacity'),
              strokeWeight: feature.getProperty('stroke-width'),
              title: feature.getProperty('title'),
              clickable: false,
            };
          });
        }
      }
    },

    async findPlaces(searchTerm, map) {
      const { Place } = await google.maps.importLibrary('places');
      const { AdvancedMarkerElement } = await google.maps.importLibrary('marker');
      const request = {
        textQuery: searchTerm,
        fields: ['displayName', 'location'],
      };

      const { places } = await Place.searchByText(request);

      if (places.length) {
        const { LatLngBounds } = await google.maps.importLibrary('core');
        const bounds = new LatLngBounds();

        // Loop through and get all the results.
        places.forEach((place) => {
          const markerView = new AdvancedMarkerElement({
            map,
            position: place.location,
            title: place.displayName,
          });

          bounds.extend(place.location);
        });

        // zoom to the bounds if there are multiple places
        if (places.length > 1) {
          map.fitBounds(bounds);
        } else {
          map.setCenter(bounds.getCenter());
          map.setZoom(this.zoomLevel || 14);
        }
      } else {
        console.log('No results');
      }
    },

    centerLatLng() {
      let resultLatLng = null;
      if (topics?.type === 'entry') {
        const entry = topics.collection[0].entries[0];
        if (entry.position) {
          resultLatLng = new google.maps.LatLng(entry.position.lat, entry.position.lng);
        }
      } else {
        resultLatLng = new google.maps.LatLng(originLatLng.originLat, originLatLng.originLng);
      }
      return resultLatLng;
    },
  };
}
