import { translate } from '~/components/locales/translations';
import axios from 'axios';

export default function entriesTranslation(googleTranslateApiKey, source) {
  return {
    isTranslated: false,
    translationSaved: false,
    error: false,
    currentLocale: document.querySelector('html').getAttribute('lang'),
    entryFields: null,

    init() {
      if (this.$refs.translationIcon) {
        this.setTranslationButtonText();
        this.entryFields = this.createEntryFields();

        // save the original text in localStorage
        this.entryFields.forEach((field, index) => localStorage.setItem(index, field.innerHTML));

        this.$refs.translationMessage.addEventListener('click', () => {
          this.handleTranslate();
        });
      }
    },

    setTranslationButtonText() {
      // use translate to get the different messages in the correct language
      const backToOriginalMessage = translate('entry-translation', 'show_original', this.currentLocale);
      const toTranslationMessage = translate('entry-translation', 'translate', this.currentLocale);
      const translationByMessage = translate('entry-translation', 'translated_by_google', this.currentLocale);
      const errorMessage = translate('entry-translation', 'error', this.currentLocale);

      // put the relevant messages on the button
      this.$refs.translationIcon.classList.remove('hidden');
      if (this.isTranslated) {
        this.$refs.translationMessage.innerText = backToOriginalMessage;
        this.$refs.translationBy.innerText = translationByMessage;
      } else {
        this.$refs.translationMessage.innerText = toTranslationMessage;
        this.$refs.translationBy.innerText = '';
      }
      if (this.error) {
        this.$refs.errorMessage.innerText = errorMessage;
      }
    },

    // creates an array with all DOM nodes inside
    createEntryFields() {
      const entryFields = [];
      const entryContent = this.$refs.entry;
      const entryTitle = entryContent.querySelector("[data-id='title']");
      const fields = entryContent.querySelectorAll('[data-field-type]');
      entryFields.push(entryTitle);
      fields.forEach((field) => entryFields.push(field));
      return entryFields;
    },

    handleTranslate() {
      if (this.isTranslated) {
        // restore original text in DOM from localStorage
        this.entryFields.forEach((field, index) => (field.innerHTML = localStorage.getItem(index)));
        this.isTranslated = false;
      } else if (this.translationSaved) {
        // restore translations in DOM from localStorage
        this.entryFields.forEach((field, index) => (field.innerHTML = localStorage.getItem(`translated-${index}`)));
        this.isTranslated = true;
      } else {
        // get translations from google
        this.fetchGoogleTranslation();
        this.isTranslated = true;
        this.translationSaved = true;
      }
      this.setTranslationButtonText();
    },

    async fetchGoogleTranslation() {
      axios
        .post(`https://translation.googleapis.com/language/translate/v2?key=${googleTranslateApiKey}`, {
          q: this.entryFields.map((text) => text.innerHTML),
          source: source,
          target: this.currentLocale,
          format: 'text',
        })
        .then((response) => {
          const googleTranslations = response.data.data.translations;
          googleTranslations.forEach((translation, index) => {
            // update the DOM with the translated text by changing the DOM nodes in entryFields
            this.entryFields[index].innerHTML = translation.translatedText;

            // save the translated text in localStorage
            localStorage.setItem(`translated-${index}`, translation.translatedText);
          });

          this.error = false;
        })
        .catch(() => {
          this.error = true;
        });
    },
  };
}
