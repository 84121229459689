import 'cookieconsent';

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '@hotwired/turbo-rails';

// Helpers
// import '../scripts';

// Bpart Scripts
import '~/scripts/register-as-organization';
import '~/scripts/search';
import '~/scripts/status-message';
import '~/scripts/vote';
import '~/scripts/splide';

// Alpine
import Alpine from 'alpinejs';
import dropdown from '../alpine/dropdown-public';
import entriesSearch from '../alpine/entries-search';
import entriesTranslation from '../alpine/entries-translation';
import fileUpload from '../alpine/file-upload-public';
import formErrorHandler from '../alpine/form-error-handler';
import googleMapsPublic from '../alpine/google-maps-public';
import newsItemsCategories from '../alpine/news-items-categories';
import skiplinks from '../alpine/skiplinks';
import socialMediaSharer from '../alpine/social-media-sharer';
import highlight from '../alpine/search-result-highlighter';
import voterRRN from '../alpine/voter-rrn';

// Alpine
window.Alpine = Alpine;
Alpine.data('dropdown', dropdown);
Alpine.data('entriesSearch', entriesSearch);
Alpine.data('entriesTranslation', entriesTranslation);
Alpine.data('fileUpload', fileUpload);
Alpine.data('formErrorHandler', formErrorHandler);
Alpine.data('googleMapsPublic', googleMapsPublic);
Alpine.data('newsItemsCategories', newsItemsCategories);
Alpine.data('skiplinks', skiplinks);
Alpine.data('socialMediaSharer', socialMediaSharer);
Alpine.data('highlight', highlight);
Alpine.data('voterRRN', voterRRN);

document.addEventListener('DOMContentLoaded', () => {
  Alpine.start();

  const flashMessage = document.getElementById('flash-message');
  if (flashMessage) setTimeout(flashMessage.focus(), 0);
});
